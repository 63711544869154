import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AppRoutingService {
    readonly URL_OTKWEB_NOVIDADES = 'http://www.otk.com.br/otk-web-novidades';

    primeirosPassosFornecedor$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);

    constructor(private _router: Router) {}

    windowOpenURLNovidades() {
        window.open(this.URL_OTKWEB_NOVIDADES, '_blank');
    }

    windowOpenURL(url: string) {
        window.open(url, '_blank');
        window.focus();
    }

    redirect(url: string) {
        this._router.navigate([url]);
    }

    genericRoute(route) {
        this._router.navigate(['../'], { relativeTo: route });
    }

    genericRouteEmpty() {
        this._router.navigate(['/']);
    }
    login() {
        this._router.navigate(['/login']);
    }

    genericDetailRoute(route: any) {
        this._router.navigate(['detalhe'], { relativeTo: route });
    }

    genericParamsRoute(params: any, route: any) {
        this._router.navigate(['detalhe'], {
            queryParams: params,
            relativeTo: route,
        });
    }

    sistemaAvisoLoginDefault() {
        this._router.navigate(['/login']);
    }

    signUp() {
        this._router.navigate(['/signup']);
    }

    sistemaAvisoModulo() {
        this._router.navigate(['/sistema/aviso/modulo']);
    }

    sistemaAvisoAdministrador() {
        this._router.navigate(['/sistema/aviso/administrador']);
    }

    sistemaAvisoColaborador() {
        this._router.navigate(['/sistema/aviso/colaborador-invalido']);
    }

    sistemaAvisoExpiracao() {
        this._router.navigate(['/sistema/aviso/expiracao']);
    }

    sistemaAvisoLogin() {
        this._router.navigate(['/sistema/aviso/login-interno']);
    }

    sistemaAvisoFilial() {
        this._router.navigate(['/sistema/aviso/filial-invalida']);
    }

    sistemaAvisoErro() {
        this._router.navigate(['/sistema/aviso/erro']);
    }

    sistemaAvisoSemPermissao() {
        this._router.navigate(['/sistema/aviso/sem-permissao']);
    }

    sistemaExportacao() {
        this._router.navigate(['/sistema-exportacao']);
    }

    exportacaoDeRelatorio() {
        this._router.navigate(['/exportacao-de-relatorio']);
    }

    home() {
        this._router.navigate(['/home']);
    }

    homeComprasGerencial(queryParams: any = null) {
        this._router.navigate(['/home/comprasGerencial'], {
            queryParams,
        });
    }
    homeComprasOperacional(queryParams: any = null) {
        this._router.navigate(['/home/comprasOperacional'], {
            queryParams,
        });
    }

    homeEstoque(queryParams: any = null) {
        this._router.navigate(['/home/estoque'], {
            queryParams,
        });
    }

    homeVendas(queryParams: any = null) {
        this._router.navigate(['/home/vendas'], {
            queryParams,
        });
    }

    homeFinanceiro(queryParams: any = null) {
        this._router.navigate(['/home/financeiro'], {
            queryParams,
        });
    }

    detalheCodColaborador(obj: any, route) {
        this._router.navigate(['detalhe', obj.codigoDoColaborador], {
            relativeTo: route,
        });
    }

    detalheNumDoLote(obj: any, route) {
        this._router.navigate(['detalhe', obj.numeroDoLote], {
            relativeTo: route,
        });
    }
    /////////// CADASTROS ///////////

    cadastroDoColaboradorDetalhe(id: number = null) {
        if (id) {
            this._router.navigate(['/cadastros/colaborador/detalhe', id]);
        } else {
            this._router.navigate(['/cadastros/colaborador/detalhe']);
        }
    }

    cadastroDoColaboradorUsuario(id: number = null) {
        if (id) {
            this._router.navigate(['/cadastros/colaborador/detalhe', id]);
        } else {
            this._router.navigate(['/cadastros/colaborador/']);
        }
    }

    cadastroDoColaboradorRequisitante(id: number = null) {
        if (id) {
            this._router.navigate(['/cadastros/colaborador/detalhe', id]);
        } else {
            this._router.navigate(['/cadastros/colaborador/']);
        }
    }

    cadastroProjeto() {
        this._router.navigate(['/cadastros/projeto']);
    }

    cadastroProjetoDetalhe(id: number = null) {
        if (id) {
            this._router.navigate(['/cadastros/projeto/detalhe', id]);
        } else {
            this._router.navigate(['/cadastros/projeto/detalhe']);
        }
    }

    cadastroContratoAtivos() {
        this._router.navigate(['/cadastros/contrato/ativos']);
    }

    cadastroContratoInativos() {
        this._router.navigate(['/cadastros/contrato/inativos']);
    }

    cadastroContratoDetalhe(id: number = null) {
        if (id) {
            this._router.navigate(['/cadastros/contrato/detalhe', id]);
        } else {
            this._router.navigate(['/cadastros/contrato/detalhe']);
        }
    }

    cadastroSegmento() {
        this._router.navigate(['/cadastros/segmento']);
    }

    cadastroRamoDeAtividade() {
        this._router.navigate(['/cadastros/ramo-de-atividade']);
    }

    cadastroDoColaboradorIncluir() {
        this._router.navigate(['/cadastros/colaborador'], {
            queryParams: { incluir: true },
        });
    }

    cadastroDoGrupoDeColaboradorDetalhe(id: number = null) {
        if (id) {
            this._router.navigate([
                '/cadastros/grupo-de-colaboradores/detalhe',
                id,
            ]);
        } else {
            this._router.navigate([
                '/cadastros/grupo-de-colaboradores/detalhe',
            ]);
        }
    }

    cadastroDeProduto() {
        this._router.navigate(['/cadastros/produto']);
    }

    cadastroDeProdutoPesquisar() {
        this._router.navigate(['/cadastros/produto/pesquisar']);
    }

    cadastroDeProdutoDetalhe(id: number = null) {
        if (id) {
            this._router.navigate(['/cadastros/produto/detalhe', id]);
        } else {
            this._router.navigate(['/cadastros/produto/detalhe']);
        }
    }

    cadastroDeFornecedor() {
        this._router.navigate(['/cadastros/fornecedor']);
    }

    cadastroDeFornecedorPesquisar() {
        this._router.navigate(['/cadastros/fornecedor/pesquisar']);
    }

    cadastroDeFornecedorDetalhe(id: number = null) {
        if (id) {
            this._router.navigate(['/cadastros/fornecedor/detalhe', id]);
        } else {
            this._router.navigate(['/cadastros/fornecedor/detalhe']);
        }
    }

    cadastroDeFornecedorPrimeirosPassos() {
        this._router.navigate(['/cadastros/fornecedor/primeiros-passos']);
    }

    cadastroDeCliente() {
        this._router.navigate(['/crm/clientes']);
    }

    cadastroDeClientePesquisar() {
        this._router.navigate(['/crm/clientes/pesquisar']);
    }

    cadastroDeClienteDetalhe(id: number = null) {
        if (id) {
            this._router.navigate(['/crm/clientes/detalhe', id]);
        } else {
            this._router.navigate(['/crm/clientes/detalhe']);
        }
    }

    cadastroDeClienteDetalheMaisInformacoes(e: any) {
        if (e) {
            this._router.navigate(
                ['/crm/clientes/detalhe', e.codigoDoCliente],
                {
                    queryParams: {
                        agenda: true,
                        cli: e.codigoDoCliente,
                        seq: e.seqHistorico,
                    },
                }
            );
        } else {
            this._router.navigate(['/crm/clientes/detalhe']);
        }
    }

    /////////// COMPRAS ///////////

    private _timelineCompras(id: number, titulo: string) {
        if (!id) {
        }
        this._router.navigate(['/timeline/compras', id], {
            queryParams: { titulo },
        });
    }

    timelineComprasRequisicao(id: number) {
        this._timelineCompras(id, 'Requisição de Compra');
    }

    timelineComprasOrdem(id: number) {
        this._timelineCompras(id, 'Ordem de Compra');
    }

    timelineComprasPedido(id: number) {
        this._timelineCompras(id, 'Pedido de Compra');
    }

    timelineComprasRecebimento(id: number) {
        this._timelineCompras(id, 'Recebimento - Pedido de Compra');
    }

    requisicaoDeCompraAbertas() {
        this._router.navigate(['/compras/requisicao-de-compra/abertas']);
    }

    requisicaoDeCompraAbertasIncluir() {
        this._router.navigate(['/compras/requisicao-de-compra/abertas'], {
            queryParams: { incluir: true },
        });
    }

    requisicaoDeCompraEncerradas() {
        this._router.navigate(['/compras/requisicao-de-compra/encerradas']);
    }

    requisicaoDeCompraPesquisar() {
        this._router.navigate(['/compras/requisicao-de-compra/pesquisar']);
    }

    requisicaoDeCompraDetalhe(numeroDaRequisicao: number = null) {
        if (numeroDaRequisicao) {
            this._router.navigate([
                '/compras/requisicao-de-compra/detalhe',
                numeroDaRequisicao,
            ]);
        } else {
            this._router.navigate(['/compras/requisicao-de-compra/detalhe']);
        }
    }

    ordemDeCompraLoteDetalhe(id: number = null) {
        if (id) {
            this._router.navigate([
                '/compras/ordem-de-compra-lote/detalhe',
                id,
            ]);
        } else {
            this._router.navigate(['/compras/ordem-de-compra-lote/detalhe']);
        }
    }

    ordemDeCompraLoteAbertas() {
        this._router.navigate(['/compras/ordem-de-compra-lote/abertas']);
    }

    ordemDeCompraLoteAbertasIncluir() {
        this._router.navigate(['/compras/ordem-de-compra-lote/abertas'], {
            queryParams: { incluir: true },
        });
    }

    ordemDeCompraLoteEncerradas() {
        this._router.navigate(['/compras/ordem-de-compra-lote/encerradas']);
    }

    ordemDeCompraAbertas() {
        this._router.navigate(['/compras/ordem-de-compra/abertas']);
    }

    ordemDeCompraAbertasIncluir() {
        this._router.navigate(['/compras/ordem-de-compra/abertas'], {
            queryParams: { incluir: true },
        });
    }

    ordemDeCompraEncerradas() {
        this._router.navigate(['/compras/ordem-de-compra/encerradas']);
    }

    ordemDeCompraPesquisar() {
        this._router.navigate(['/compras/ordem-de-compra/pesquisar']);
    }

    ordemDeCompraDetalhe(id: number = null) {
        if (id) {
            this._router.navigate(['/compras/ordem-de-compra/detalhe', id]);
        } else {
            this._router.navigate(['/compras/ordem-de-compra/detalhe']);
        }
    }

    ordemDeCompraProdutoDetalhe(codinterno: number) {
        if (codinterno) {
            this._router.navigate(['/compras/ordem-de-compra-produto'], {
                queryParams: { codigoInterno: codinterno },
            });
        } else {
            this._router.navigate(['/compras/ordem-de-compra/abertas']);
        }
    }

    pedidoDeCompraAbertas() {
        this._router.navigate(['/compras/pedido-de-compra/abertas']);
    }

    pedidoDeCompraAbertasIncluir() {
        this._router.navigate(['/compras/pedido-de-compra/abertas'], {
            queryParams: { incluir: true },
        });
    }

    pedidoDeCompraEncerradas() {
        this._router.navigate(['/compras/pedido-de-compra/encerradas']);
    }

    pedidoDeCompraPesquisar() {
        this._router.navigate(['/compras/pedido-de-compra/pesquisar']);
    }

    pedidoDeCompraPesquisarPorNumeroDaOrdem(numeroDaOrdem: number) {
        if (numeroDaOrdem) {
            this._router.navigate(['/compras/pedido-de-compra/pesquisar'], {
                queryParams: { numeroDaOrdem: numeroDaOrdem },
            });
        }
    }

    pedidoDeCompraPesquisarPorNumeroDoPedidoPrincipal(
        numeroDoPedidoPrincipal: number
    ) {
        if (numeroDoPedidoPrincipal) {
            this._router.navigate(['/compras/pedido-de-compra/pesquisar'], {
                queryParams: {
                    numeroDoPedidoPrincipal: numeroDoPedidoPrincipal,
                },
            });
        }
    }

    pedidoDeCompraDetalhe(numeroDoPedido: number, seq: number) {
        if (!numeroDoPedido) {
            return;
        }
        this._router.navigate([
            '/compras/pedido-de-compra/detalhe',
            numeroDoPedido,
            seq,
        ]);
    }

    cotacaoDeCompra(numeroDaOrdem: number, numeroDaCotacao: number) {
        if (!numeroDaOrdem || !numeroDaCotacao) {
            return;
        }
        this._router.navigate([
            '/compras/ordem-de-compra/cotacao',
            numeroDaOrdem,
            numeroDaCotacao,
        ]);
    }

    cotacaoDeCompraIncluir(numeroDaOrdem: number) {
        if (!numeroDaOrdem) {
            return;
        }
        this._router.navigate([
            '/compras/ordem-de-compra/cotacao/incluir',
            numeroDaOrdem,
        ]);
    }

    cotacaoDeCompraComparacao(numeroDaOrdem: number) {
        if (!numeroDaOrdem) {
            return;
        }
        this._router.navigate([
            '/compras/ordem-de-compra/cotacao/comparacao',
            numeroDaOrdem,
        ]);
    }

    cotacaoDeCompraComparacaoPivotGrid(numeroDaOrdem: number) {
        if (!numeroDaOrdem) {
            return;
        }
        this._router.navigate([
            '/compras/ordem-de-compra/cotacao/comparacao/pivot-grid',
            numeroDaOrdem,
        ]);
    }

    cotacaoDeCompraSelecaoFornecedores(numeroDaOrdem: number) {
        if (!numeroDaOrdem) {
            return;
        }
        this._router.navigate([
            '/compras/ordem-de-compra/cotacao/selecao-fornecedores',
            numeroDaOrdem,
        ]);
    }

    cotacaoDeCompraOrdens(numeroDaOrdem: number = null) {
        if (numeroDaOrdem) {
            this._router.navigate(['/compras/cotacoes/ordens', numeroDaOrdem]);
        } else {
            this._router.navigate(['/compras/cotacoes/ordens']);
        }
    }

    cotacaoComparacao(obj: any) {
        if (obj) {
            this._router.navigate(
                [
                    'compras/ordem-de-compra/cotacao/comparacao',
                    obj.numeroDaOrdem,
                ],
                {
                    queryParams: {
                        numeroDaCotacao: obj.numeroDaCotacao,
                        codigoInterno: obj.codigoInterno,
                        selecionados: true,
                    },
                }
            );
        }
    }

    recebimentoTabs() {
        this._router.navigate(['/compras/recebimento/tabs']);
    }

    recebimentoPendentes() {
        this._router.navigate(['/compras/recebimento/pendentes']);
    }

    recebimentoProcessados() {
        this._router.navigate(['/compras/recebimento/processados']);
    }

    recebimentoCancelados() {
        this._router.navigate(['/compras/recebimento/cancelados']);
    }

    recebimentoPedido(numeroDoPedido: number) {
        if (numeroDoPedido) {
            this._router.navigate([
                '/compras/recebimento/pedido',
                numeroDoPedido,
            ]);
        }
    }

    recebimentoPesquisar(numeroDoPedido: number = null) {
        if (numeroDoPedido) {
            this._router.navigate(['/compras/recebimento/pesquisar'], {
                queryParams: { numeroDoPedido: numeroDoPedido },
            });
        } else {
            this._router.navigate(['/compras/recebimento/pesquisar']);
        }
    }

    recebimentoDetalhe() {
        this._router.navigate(['compras/recebimento/detalhe/pendente']);
    }

    recebimentoDetalheProcessado() {
        this._router.navigate(['compras/recebimento/detalhe/processado']);
    }

    cotacoesDetalhe(numeroDaOrdem: number, numeroDaCotacao: number) {
        if (!numeroDaOrdem || !numeroDaCotacao) {
            return;
        }
        this._router.navigate([
            '/compras/ordem-de-compra/cotacao',
            numeroDaOrdem,
            numeroDaCotacao,
        ]);
    }

    notasRecebidasSincronizadas() {
        this._router.navigate(['/compras/notas-recebidas/sincronizadas']);
    }
    notasRecebidasSincronizadasDetalhe(id: number = null) {
        if (id) {
            this._router.navigate([
                '/compras/notas-recebidas/sincronizadas/detalhe',
                id,
            ]);
        } else {
            this._router.navigate([
                '/compras/notas-recebidas/sincronizadas/detalhe',
            ]);
        }
    }

    notasRecebidasCompras() {
        this._router.navigate(['/compras/notas-recebidas/compras']);
    }
    notasRecebidasComprasDetalhe(id: number = null) {
        if (id) {
            this._router.navigate([
                '/compras/notas-recebidas/compras/detalhe',
                id,
            ]);
        } else {
            this._router.navigate(['/compras/notas-recebidas/compras/detalhe']);
        }
    }

    sugestaoDeCompras(id: number) {
        if (!id) {
            return;
        }
        this._router.navigate(['/compras/sugestao-de-compras', id]);
    }

    regraDeAprovacao() {
        this._router.navigate(['/compras/regra-de-aprovacao']);
    }

    bradescoQuestionario() {
        this._router.navigate(['/compras/bradesco/questionario']);
    }
    bradescoQuestionarioDetalhe(id: number = null) {
        if (id) {
            this._router.navigate([
                '/compras/bradesco/questionario/detalhe',
                id,
            ]);
        } else {
            this._router.navigate(['/compras/bradesco/questionario/detalhe']);
        }
    }

    configuracaoDeCompras(codigoFilial: number = null) {
        if (codigoFilial) {
            this._router.navigate(['/compras/configuracao-de-compras'], {
                queryParams: {
                    filial: codigoFilial,
                },
            });
        } else {
            this._router.navigate(['/compras/configuracao-de-compras']);
        }
    }

    configuracaoDeComprasDetalhe(queryParams: any = null) {
        if (queryParams) {
            this._router.navigate(
                ['/compras/configuracao-de-compras/detalhe'],
                {
                    queryParams: queryParams,
                }
            );
        } else {
            this._router.navigate(['/compras/configuracao-de-compras/detalhe']);
        }
    }

    /////////// ESTOQUE ///////////

    private _timelineEstoque(id: number, titulo: string) {
        if (!id) {
        }
        this._router.navigate(['/timeline/estoque', id], {
            queryParams: { titulo },
        });
    }
    timelineEstoqueRequisicao(id: number) {
        this._timelineEstoque(id, 'Requisição de Estoque');
    }
    timelineEstoqueExpedicao(id: number) {
        this._timelineEstoque(id, 'Expedição - Requisição de Estoque');
    }

    requisicaoDeEstoqueAbertas() {
        this._router.navigate(['/estoque/requisicao-de-estoque/abertas']);
    }

    requisicaoDeEstoqueProcessadas() {
        this._router.navigate(['/estoque/requisicao-de-estoque/encerradas']);
    }

    requisicaoDeEstoquePesquisar() {
        this._router.navigate(['/estoque/requisicao-de-estoque/pesquisar']);
    }

    requisicaoDeEstoqueDetalhe(numeroDaRequisicao: number = null) {
        if (numeroDaRequisicao) {
            this._router.navigate([
                '/estoque/requisicao-de-estoque/detalhe',
                numeroDaRequisicao,
            ]);
        } else {
            this._router.navigate(['/estoque/requisicao-de-estoque/detalhe']);
        }
    }

    requisicaoDeEstoqueNovoRegistro() {
        this._router.navigate(['/estoque/requisicao-de-estoque/abertas'], {
            queryParams: { incluir: true },
        });
    }

    entradaDeEstoqueGrid() {
        this._router.navigate(['/estoque/entrada-de-estoque']);
    }

    entradaDeEstoque(numeroDaEntrada: number, seq: number) {
        if (numeroDaEntrada) {
            this._router.navigate([
                '/estoque/entrada-de-estoque/detalhe',
                numeroDaEntrada,
                seq,
            ]);
        } else {
            this._router.navigate(['/estoque/entrada-de-estoque']);
        }
    }

    entradaDeEstoqueIncluir(
        codigoDaFilial: number,
        codigoDoAlmoxarifado: number,
        idProd: number
    ) {
        if (!codigoDaFilial || !codigoDoAlmoxarifado || !idProd) {
            return;
        }

        this._router.navigate(['/estoque/entrada-de-estoque/incluir'], {
            queryParams: {
                fil: codigoDaFilial,
                almox: codigoDoAlmoxarifado,
                idprod: idProd,
            },
        });
    }

    entradaDeEstoqueDetalhe(numeroDaEntrada: number) {
        if (numeroDaEntrada) {
            this._router.navigate([
                '/estoque/entrada-de-estoque/detalhe',
                numeroDaEntrada,
            ]);
        } else {
            this._router.navigate(['/estoque/entrada-de-estoque']);
        }
    }

    entradaDeEstoquePesquisar() {
        this._router.navigate(['/estoque/entrada-de-estoque/pesquisar']);
    }

    entradaDeEstoqueLoteAbertas() {
        this._router.navigate(['/estoque/entrada-em-lote/abertas']);
    }

    entradaDeEstoqueLoteEncerradas() {
        this._router.navigate(['/estoque/entrada-em-lote/encerradas']);
    }

    saidaDeEstoqueGrid() {
        this._router.navigate(['/estoque/saida-de-estoque']);
    }

    saidaDeEstoque(numeroDaSaida: number, seq: number) {
        if (numeroDaSaida) {
            this._router.navigate([
                '/estoque/saida-de-estoque/detalhe',
                numeroDaSaida,
                seq,
            ]);
        } else {
            this._router.navigate(['/estoque/saida-de-estoque']);
        }
    }

    saidaDeEstoqueDetalhe(numeroDaMovimentacao: number) {
        if (numeroDaMovimentacao) {
            this._router.navigate([
                '/estoque/saida-de-estoque/detalhe',
                numeroDaMovimentacao,
            ]);
        }
    }

    saidaDeEstoquePesquisar() {
        this._router.navigate(['/estoque/saida-de-estoque/pesquisar']);
    }

    saidaDeEstoqueIncluir(
        codigoDaFilial: number,
        codigoDoAlmoxarifado: number,
        idProd: number
    ) {
        if (!codigoDaFilial || !codigoDoAlmoxarifado || !idProd) {
            return;
        }

        this._router.navigate(['/estoque/saida-de-estoque/incluir'], {
            queryParams: {
                fil: codigoDaFilial,
                almox: codigoDoAlmoxarifado,
                idprod: idProd,
            },
        });
    }

    expedicao() {
        this._router.navigate(['/estoque/expedicao']);
    }

    expedicaoPendentes() {
        this._router.navigate(['/estoque/expedicao/pendentes']);
    }

    expedicaoEmProcessamento() {
        this._router.navigate(['/estoque/expedicao/em-processamento']);
    }

    expedicaoProcessados() {
        this._router.navigate(['/estoque/expedicao/processados']);
    }

    expedicaoCancelados() {
        this._router.navigate(['/estoque/expedicao/cancelados']);
    }

    expedicaoPesquisar(numeroDaRequisicao: number = null) {
        if (numeroDaRequisicao) {
            this._router.navigate(['/estoque/expedicao/pesquisar'], {
                queryParams: { numeroDaRequisicao: numeroDaRequisicao },
            });
        } else {
            this._router.navigate(['/estoque/expedicao/pesquisar']);
        }
    }

    expedicaoDetalhePendentes() {
        this._router.navigate(['estoque/expedicao/detalhe/pendentes']);
    }

    expedicaoDetalheProcessado() {
        this._router.navigate(['estoque/expedicao/detalhe/processado']);
    }

    expedicaoRequisicaoDetalhe(
        codigoDaMovimentacao: number,
        numeroDaMovimentacao: number,
        numeroDaRequisicao: number,
        seq: number
    ) {
        if (
            codigoDaMovimentacao &&
            numeroDaMovimentacao &&
            numeroDaRequisicao
        ) {
            this._router.navigate([
                '/estoque/expedicao/detalhe',
                codigoDaMovimentacao,
                numeroDaMovimentacao,
                numeroDaRequisicao,
                seq,
            ]);
        }
    }

    transferenciaDeEstoqueDetalhe(numeroDaMovimentacao: number, seq: number) {
        if (!numeroDaMovimentacao || !seq) {
            return;
        }

        this._router.navigate([
            '/estoque/transferencia-de-estoque/detalhe',
            numeroDaMovimentacao,
            seq,
        ]);
    }

    transferenciaDeEstoquePesquisar() {
        this._router.navigate(['/estoque/transferencia-de-estoque/pesquisar']);
    }

    transferenciaDeEstoque() {
        this._router.navigate(['/estoque/transferencia-de-estoque']);
    }

    transferenciaDeEstoqueIncluir(
        codigoDaFilial: number,
        codigoDoAlmoxarifado: number,
        idProd: number
    ) {
        if (!codigoDaFilial || !codigoDoAlmoxarifado || !idProd) {
            return;
        }

        this._router.navigate(['/estoque/transferencia-de-estoque/incluir'], {
            queryParams: {
                fil: codigoDaFilial,
                almox: codigoDoAlmoxarifado,
                idprod: idProd,
            },
        });
    }

    inventario() {
        this._router.navigate(['estoque/inventario']);
    }

    inventarioDetalhe(idInventario: number) {
        if (!idInventario) {
            return;
        }
        this._router.navigate(['estoque/inventario/detalhe', idInventario]);
    }

    enderecamentoEstoque(idProd: number) {
        if (idProd) {
            this._router.navigate(['/estoque/enderecamentos-produto', idProd]);
        }
    }

    /////////// VENDAS ///////////

    previsaoFaturamentoServicos() {
        this._router.navigate(['/vendas/previsao/servicos']);
    }
    previsaoFaturamentoServicosDetalhe(id: number = null) {
        if (id) {
            this._router.navigate(['/vendas/previsao/servicos/detalhe', id]);
        } else {
            this._router.navigate(['/vendas/previsao/servicos/detalhe']);
        }
    }
    previsaoFaturamentoFatura() {
        this._router.navigate(['/vendas/previsao/fatura']);
    }
    previsaoFaturamentoFaturaDetalhe() {
        this._router.navigate(['/vendas/previsao/fatura/detalhe']);
    }

    notasRecebidasVendas() {
        this._router.navigate(['/vendas/nota-recebida']);
    }
    notasRecebidasVendasDetalhe(id: number = null) {
        if (id) {
            this._router.navigate(['/vendas/nota-recebida/detalhe', id]);
        } else {
            this._router.navigate(['/vendas/nota-recebida/detalhe']);
        }
    }

    orcamentosAbertos() {
        this._router.navigate(['/vendas/orcamentos']);
    }

    orcamentosProcessados() {
        this._router.navigate(['/vendas/orcamentos/processados']);
    }

    orcamentosCancelados() {
        this._router.navigate(['/vendas/orcamentos/cancelados']);
    }

    orcamentosPesquisar() {
        this._router.navigate(['/vendas/orcamentos/pesquisar']);
    }

    orcamentosDetalheIncluir() {
        this._router.navigate(['/vendas/orcamentos/detalhe'], {
            queryParams: { incluir: true },
        });
    }

    orcamentosDetalhe(id: number = null) {
        if (id) {
            this._router.navigate(['/vendas/orcamentos/detalhe', id]);
        } else {
            this._router.navigate(['/vendas/orcamentos/detalhe']);
        }
    }

    propostasPandoraAbertos() {
        this._router.navigate(['/vendas/propostas-pandora']);
    }
    propostasPandoraProcessados() {
        this._router.navigate(['/vendas/propostas-pandora/processados']);
    }
    propostasPandoraCancelados() {
        this._router.navigate(['/vendas/propostas-pandora/cancelados']);
    }
    propostasPandoraPesquisar() {
        this._router.navigate(['/vendas/propostas-pandora/pesquisar']);
    }
    propostasPandoraDetalhe(id: number = null) {
        if (id) {
            this._router.navigate(['/vendas/propostas-pandora/detalhe', id]);
        } else {
            this._router.navigate(['/vendas/propostas-pandora/detalhe']);
        }
    }
    propostasPandoraDetalheIncluir() {
        this._router.navigate(['/vendas/propostas-pandora/detalhe'], {
            queryParams: { incluir: true },
        });
    }

    faturaAbertas() {
        this._router.navigate(['/vendas/fatura']);
    }

    faturaProcessadas() {
        this._router.navigate(['/vendas/fatura/processadas']);
    }

    faturaCanceladas() {
        this._router.navigate(['/vendas/fatura/canceladas']);
    }

    faturaPesquisar() {
        this._router.navigate(['/vendas/fatura/pesquisar']);
    }

    faturaDetalheIncluir() {
        this._router.navigate(['/vendas/fatura/detalhe'], {
            queryParams: { incluir: true },
        });
    }

    faturaDetalhe(id: number = null) {
        if (id) {
            this._router.navigate(['/vendas/fatura/detalhe', id]);
        } else {
            this._router.navigate(['/vendas/fatura/detalhe']);
        }
    }

    ordemAbertas() {
        this._router.navigate(['/vendas/ordem-de-servico']);
    }

    ordemProcessadas() {
        this._router.navigate(['/vendas/ordem-de-servico/processadas']);
    }

    ordemCanceladas() {
        this._router.navigate(['/vendas/ordem-de-servico/canceladas']);
    }

    ordemPesquisar() {
        this._router.navigate(['/vendas/ordem-de-servico/pesquisar']);
    }

    ordemDetalhe(id: number = null) {
        if (id) {
            this._router.navigate(['/vendas/ordem-de-servico/detalhe', id]);
        } else {
            this._router.navigate(['/vendas/ordem-de-servico/detalhe']);
        }
    }

    ordemDetalheIncluir() {
        this._router.navigate(['/vendas/ordem-de-servico/detalhe'], {
            queryParams: { incluir: true },
        });
    }

    pedidoDeVendaAbertos() {
        this._router.navigate(['/vendas/pedido-de-venda']);
    }

    pedidoDeVendaProcessados() {
        this._router.navigate(['/vendas/pedido-de-venda/processados']);
    }

    pedidoDeVendaCancelados() {
        this._router.navigate(['/vendas/pedido-de-venda/cancelados']);
    }

    pedidoDeVendaPesquisar() {
        this._router.navigate(['/vendas/pedido-de-venda/pesquisar']);
    }

    pedidoDeVendaDetalhe(id: number = null) {
        if (id) {
            this._router.navigate(['/vendas/pedido-de-venda/detalhe', id]);
        } else {
            this._router.navigate(['/vendas/pedido-de-venda/detalhe']);
        }
    }

    pedidoDeVendaDetalheIncluir() {
        this._router.navigate(['/vendas/pedido-de-venda/detalhe'], {
            queryParams: { incluir: true },
        });
    }

    faturaPesquisarPorNumeroDoDocumento(numeroDoDocumento: number) {
        if (numeroDoDocumento) {
            this._router.navigate(['/vendas/fatura/pesquisar'], {
                queryParams: { numeroDoDocumento: numeroDoDocumento },
            });
        }
    }

    faturaPesquisarPorNumeroDoPV(numeroDoPV: number) {
        if (numeroDoPV) {
            this._router.navigate(['/vendas/fatura/pesquisar'], {
                queryParams: { numeroDoPV: numeroDoPV },
            });
        }
    }

    faturaPesquisarPorCodigoDoCliente(codigoDoCliente: number) {
        if (codigoDoCliente) {
            this._router.navigate(['/vendas/fatura/pesquisar'], {
                queryParams: { codigoDoCliente: codigoDoCliente },
            });
        }
    }

    ordemPesquisarPorNumeroDoPV(numeroDoPV: number) {
        if (numeroDoPV) {
            this._router.navigate(['/vendas/ordem-de-servico/pesquisar'], {
                queryParams: { numeroDoPV: numeroDoPV },
            });
        }
    }

    ordemPesquisarPorCodigoDoCliente(codigoDoCliente: number) {
        if (codigoDoCliente) {
            this._router.navigate(['/vendas/ordem-de-servico/pesquisar'], {
                queryParams: { codigoDoCliente: codigoDoCliente },
            });
        }
    }

    notaFiscalDeServicoAbertas() {
        this._router.navigate(['/vendas/nota-fiscal-servicos']);
    }

    notaFiscalDeServicoProcessadas() {
        this._router.navigate(['/vendas/nota-fiscal-servicos/emitidas']);
    }

    notaFiscalDeServicoCanceladas() {
        this._router.navigate(['/vendas/nota-fiscal-servicos/canceladas']);
    }

    notaFiscalDeServicoPesquisar() {
        this._router.navigate(['/vendas/nota-fiscal-servicos/pesquisar']);
    }

    notaFiscalDeServicosDetalhe(idnf?: number) {
        if (idnf) {
            this._router.navigate([
                '/vendas/nota-fiscal-servicos/detalhe',
                idnf,
            ]);
        } else {
            this._router.navigate(['/vendas/nota-fiscal-servicos/detalhe']);
        }
    }

    notaFiscalDeServicoPesquisarPorNumeroDoLancamento(
        numeroDoLancamento: number
    ) {
        if (numeroDoLancamento) {
            this._router.navigate(['/vendas/nota-fiscal-servicos/pesquisar'], {
                queryParams: { numeroDoLancamento: numeroDoLancamento },
            });
        }
    }

    notaFiscalDeServicoPesquisarPorNumeroDaNota(numeroDaNota: number) {
        if (numeroDaNota) {
            this._router.navigate(['/vendas/nota-fiscal-servicos/pesquisar'], {
                queryParams: { numeroDaNota: numeroDaNota },
            });
        }
    }

    notaFiscalDeServicoPesquisarPorNumeroDaOrdem(numeroDaOrdem: number) {
        if (numeroDaOrdem) {
            this._router.navigate(['/vendas/nota-fiscal-servicos/pesquisar'], {
                queryParams: { numeroDaOrdem: numeroDaOrdem },
            });
        }
    }

    notaFiscalDeServicoPesquisarPorCodigoDoCliente(codigoDoCliente: number) {
        if (codigoDoCliente) {
            this._router.navigate(['/vendas/nota-fiscal-servicos/pesquisar'], {
                queryParams: { codigoDoCliente: codigoDoCliente },
            });
        }
    }

    notaFiscalDeServicoPesquisarPorRazaoSocial(razaoSocial: number) {
        if (razaoSocial) {
            this._router.navigate(['/vendas/nota-fiscal-servicos/pesquisar'], {
                queryParams: { razaoSocial: razaoSocial },
            });
        }
    }

    notaFiscalDeServicoPesquisarPorCNPJCPF(cNPJCPF: number) {
        if (cNPJCPF) {
            this._router.navigate(['/vendas/nota-fiscal-servicos/pesquisar'], {
                queryParams: { cNPJCPF: cNPJCPF },
            });
        }
    }

    notaFiscalDeProduto() {
        this._router.navigate(['/vendas/nota-fiscal-produtos']);
    }

    notaFiscalDeProdutoEmitidas() {
        this._router.navigate(['/vendas/nota-fiscal-produtos/emitidas']);
    }

    notaFiscalDeProdutoCanceladas() {
        this._router.navigate(['/vendas/nota-fiscal-produtos/canceladas']);
    }

    notaFiscalDeProdutoDetalhe(idnf?: number) {
        if (idnf) {
            this._router.navigate([
                '/vendas/nota-fiscal-produtos/detalhe',
                idnf,
            ]);
        } else {
            this._router.navigate(['/vendas/nota-fiscal-produtos/detalhe']);
        }
    }

    notaFiscalDeProdutoPesquisar() {
        this._router.navigate(['/vendas/nota-fiscal-produtos/pesquisar']);
    }

    notaFiscalDeProdutoPesquisarPorNumeroDoLancamento(
        numeroDoLancamento: number
    ) {
        if (numeroDoLancamento) {
            this._router.navigate(['/vendas/nota-fiscal-produtos/pesquisar'], {
                queryParams: { numeroDoLancamento: numeroDoLancamento },
            });
        }
    }

    notaFiscalDeProdutoPesquisarPorNumeroDaNota(numeroDaNota: number) {
        if (numeroDaNota) {
            this._router.navigate(['/vendas/nota-fiscal-produtos/pesquisar'], {
                queryParams: { numeroDaNota: numeroDaNota },
            });
        }
    }

    notaFiscalDeProdutoPesquisarPorNumeroDaChave(numeroDaChave: number) {
        if (numeroDaChave) {
            this._router.navigate(['/vendas/nota-fiscal-produtos/pesquisar'], {
                queryParams: { numeroDaChave: numeroDaChave },
            });
        }
    }

    notaFiscalDeProdutoPesquisarPorNumeroDoPedidoDeVenda(
        numeroDoPedidoDeVenda: number
    ) {
        if (numeroDoPedidoDeVenda) {
            this._router.navigate(['/vendas/nota-fiscal-produtos/pesquisar'], {
                queryParams: { pedidoDeVenda: numeroDoPedidoDeVenda },
            });
        }
    }

    notaFiscalDeProdutoPesquisarPorCodigoDoCliente(codigoDoCliente: number) {
        if (codigoDoCliente) {
            this._router.navigate(['/vendas/nota-fiscal-produtos/pesquisar'], {
                queryParams: { codigoDoCliente: codigoDoCliente },
            });
        }
    }

    notaFiscalDeProdutoPesquisarPorRazaoSocial(razaoSocial: number) {
        if (razaoSocial) {
            this._router.navigate(['/vendas/nota-fiscal-produtos/pesquisar'], {
                queryParams: { razaoSocial: razaoSocial },
            });
        }
    }

    notaFiscalDeProdutoPesquisarPorCNPJCPF(cNPJCPF: number) {
        if (cNPJCPF) {
            this._router.navigate(['/vendas/nota-fiscal-produtos/pesquisar'], {
                queryParams: { cNPJCPF: cNPJCPF },
            });
        }
    }

    notaFiscalDeProdutoPesquisarPorCodigoInternoProduto(
        codigoInternoProduto: string
    ) {
        if (codigoInternoProduto) {
            this._router.navigate(['/vendas/nota-fiscal-produtos/pesquisar'], {
                queryParams: { codigoInternoProduto: codigoInternoProduto },
            });
        }
    }

    notaFiscalDeProdutoPesquisarPorDescricaoDoProduto(
        descricaoDoProduto: string
    ) {
        if (descricaoDoProduto) {
            this._router.navigate(['/vendas/nota-fiscal-produtos/pesquisar'], {
                queryParams: { descricaoDoProduto: descricaoDoProduto },
            });
        }
    }

    notaFiscalDeProdutoInutilizacaoDetalhe(id: number = null) {
        if (id) {
            this._router.navigate([
                '/vendas/nota-fiscal-produtos-inutilizacao/detalhe',
                id,
            ]);
        } else {
            this._router.navigate([
                '/vendas/nota-fiscal-produtos-inutilizacao/detalhe',
            ]);
        }
    }
    notaFiscalDeProdutoInutilizacaoDetalheIncluir() {
        this._router.navigate(
            ['/vendas/nota-fiscal-produtos-inutilizacao/detalhe'],
            { queryParams: { incluir: true } }
        );
    }

    pis() {
        this._router.navigate(['/vendas/pis']);
    }

    configuracaoDeVendas(codigoFilial: number = null) {
        if (codigoFilial) {
            this._router.navigate(['/vendas/configuracao-de-vendas'], {
                queryParams: {
                    filial: codigoFilial,
                },
            });
        } else {
            this._router.navigate(['/vendas/configuracao-de-vendas']);
        }
    }

    configuracaoDeVendasNotaRecebidaHabilitar(codigoFilial: number = null) {
        if (codigoFilial) {
            this._router.navigate(['/vendas/configuracao-de-vendas'], {
                queryParams: {
                    filial: codigoFilial,
                    notaRecebidaHabilitar: true,
                },
            });
        }
    }

    configuracaoDeVendasDetalhe(queryParams: any = null) {
        if (queryParams) {
            this._router.navigate(['/vendas/configuracao-de-vendas/detalhe'], {
                queryParams: queryParams,
            });
        } else {
            this._router.navigate(['/vendas/configuracao-de-vendas/detalhe']);
        }
    }

    codServico() {
        this._router.navigate(['/vendas/codigos-de-servico']);
    }

    Vendedores() {
        this._router.navigate(['/vendas/vendedores']);
    }

    /////////// FINANCEIRO ///////////

    contasAReceberAbertos() {
        this._router.navigate(['/financeiro/contas-a-receber']);
    }

    contasAReceberBaixados() {
        this._router.navigate(['/financeiro/contas-a-receber/baixados']);
    }

    contasAReceberPrejuizos() {
        this._router.navigate(['/financeiro/contas-a-receber/prejuizos']);
    }

    contasAReceberPesquisar() {
        this._router.navigate(['/financeiro/contas-a-receber/pesquisar']);
    }

    contasAReceberDetalhe(
        ex: number,
        fil: number,
        nrtrs: number,
        seqtrs: number
    ) {
        if (!ex || !fil || !nrtrs) {
            return;
        }
        this._router.navigate([
            '/financeiro/contas-a-receber/detalhe',
            ex,
            fil,
            nrtrs,
            seqtrs,
        ]);
    }

    contasAReceberDetalheSemInformacao() {
        this._router.navigate(['/financeiro/contas-a-receber/detalhe']);
    }

    contasAReceberDetalheIncluir() {
        this._router.navigate(['/financeiro/contas-a-receber/detalhe'], {
            queryParams: { incluir: true },
        });
    }

    contasAReceberDetalheNumeroDaVenda(numeroDaFatura: number) {
        if (!numeroDaFatura) {
            return;
        }
        this._router.navigate(['/financeiro/contas-a-receber/detalhe'], {
            queryParams: { numeroDoPedidoDeVenda: numeroDaFatura },
        });
    }

    contasAReceberPesquisarPorNumeroDoLancamento(numeroDoLancamento: number) {
        if (numeroDoLancamento) {
            this._router.navigate(['/financeiro/contas-a-receber/pesquisar'], {
                queryParams: { numeroDoLancamento: numeroDoLancamento },
            });
        }
    }

    contasAReceberPesquisarPorNumeroDaFatura(numeroDaFatura: number) {
        if (numeroDaFatura) {
            this._router.navigate(['/financeiro/contas-a-receber/pesquisar'], {
                queryParams: { numeroDaFatura: numeroDaFatura },
            });
        }
    }

    contasAReceberPesquisarPorCodigoDoCliente(codigoDoCliente: number) {
        if (codigoDoCliente) {
            this._router.navigate(['/financeiro/contas-a-receber/pesquisar'], {
                queryParams: { codigoDoCliente: codigoDoCliente },
            });
        }
    }

    contasAReceberPesquisarPorNumeroDoDocumento(numeroDoDocumento: string) {
        if (numeroDoDocumento) {
            this._router.navigate(['/financeiro/contas-a-receber/pesquisar'], {
                queryParams: { numeroDoDocumento: numeroDoDocumento },
            });
        }
    }

    contasAReceberPesquisarPorNumeroDaNFe(numeroDaNFe: number) {
        if (numeroDaNFe) {
            this._router.navigate(['/financeiro/contas-a-receber/pesquisar'], {
                queryParams: { numeroDaNFe: numeroDaNFe },
            });
        }
    }

    contasAReceberPesquisarPorNumeroDoIdBoletoWS(numeroDoIdBoletoWS: string) {
        if (numeroDoIdBoletoWS) {
            this._router.navigate(['/financeiro/contas-a-receber/pesquisar'], {
                queryParams: { numeroDoIdBoletoWS: numeroDoIdBoletoWS },
            });
        }
    }

    contasAReceberStatusDoPagamentoD0() {
        this._router.navigate(['/financeiro/contas-a-receber'], {
            queryParams: { periodo: 'todos', statusDoPagamento: 'hoje' },
        });
    }

    contasAReceberStatusDoPagamentoTodos() {
        this._router.navigate(['/financeiro/contas-a-receber'], {
            queryParams: { periodo: 'todos', statusDoPagamento: 'atrasados' },
        });
    }

    contasAPagarAbertos() {
        this._router.navigate(['/financeiro/contas-a-pagar']);
    }

    contasAPagarBaixados() {
        this._router.navigate(['/financeiro/contas-a-pagar/baixados']);
    }

    contasAPagarPesquisar() {
        this._router.navigate(['/financeiro/contas-a-pagar/pesquisar']);
    }

    contasAPagarDetalhe(
        ex: number,
        fil: number,
        nrtrs: number,
        seqtrs: number
    ) {
        if (!ex || !fil || !nrtrs) {
            return;
        }
        this._router.navigate([
            '/financeiro/contas-a-pagar/detalhe',
            ex,
            fil,
            nrtrs,
            seqtrs,
        ]);
    }

    contasAPagarDetalheSemInformacao() {
        this._router.navigate(['/financeiro/contas-a-pagar/detalhe']);
    }

    contasAPagarDetalheIncluir() {
        this._router.navigate(['/financeiro/contas-a-pagar/detalhe'], {
            queryParams: { incluir: true },
        });
    }

    contasAPagarPorNumeroDoPedido(numeroDoPedido: number) {
        if (numeroDoPedido) {
            this._router.navigate(['/financeiro/contas-a-pagar/detalhe'], {
                queryParams: { numeroDoPedidoDeCompra: numeroDoPedido },
            });
        }
    }

    contasAPagarPorNumeroDoPedidoPesquisar(numeroDoPC: number) {
        if (numeroDoPC) {
            this._router.navigate(['/financeiro/contas-a-pagar/pesquisar'], {
                queryParams: { numeroDoPC: numeroDoPC },
            });
        } else {
            this._router.navigate(['/financeiro/contas-a-pagar/pesquisar']);
        }
    }

    contasAPagarPesquisarPorNumeroDoLancamento(numeroDoLancamento: number) {
        if (numeroDoLancamento) {
            this._router.navigate(['/financeiro/contas-a-pagar/pesquisar'], {
                queryParams: { numeroDoLancamento: numeroDoLancamento },
            });
        }
    }

    contasAPagarPesquisarPorNumeroDoPC(numeroDoPC: number) {
        if (numeroDoPC) {
            this._router.navigate(['/financeiro/contas-a-pagar/pesquisar'], {
                queryParams: { numeroDoPC: numeroDoPC },
            });
        }
    }

    contasAPagarPesquisarPorCodigoDoFornecedor(codigoDoFornecedor: number) {
        if (codigoDoFornecedor) {
            this._router.navigate(['/financeiro/contas-a-pagar/pesquisar'], {
                queryParams: { codigoDoFornecedor: codigoDoFornecedor },
            });
        }
    }

    contasAPagarPesquisarPorNumeroDoDocumento(numeroDoDocumento: number) {
        if (numeroDoDocumento) {
            this._router.navigate(['/financeiro/contas-a-pagar/pesquisar'], {
                queryParams: { numeroDoDocumento: numeroDoDocumento },
            });
        }
    }

    contasAPagarStatusDoPagamentoD0() {
        this._router.navigate(['/financeiro/contas-a-pagar'], {
            queryParams: { periodo: 'todos', statusDoPagamento: 'hoje' },
        });
    }

    contasAPagarStatusDoPagamentoTodos() {
        this._router.navigate(['/financeiro/contas-a-pagar'], {
            queryParams: { periodo: 'todos', statusDoPagamento: 'atrasados' },
        });
    }

    caixaBancos() {
        this._router.navigate(['/financeiro/caixa-bancos']);
    }

    caixaBancosDetalheSemInformacao() {
        this._router.navigate(['/financeiro/caixa-bancos/detalhe']);
    }

    caixaBancosDetalhe(ex: number, fil: number, nrtrs: number, seqtrs: number) {
        if (!ex || !fil || !nrtrs) {
            return;
        }
        this._router.navigate([
            '/financeiro/caixa-bancos/detalhe',
            ex,
            fil,
            nrtrs,
            seqtrs,
        ]);
    }

    duplicataCompras() {
        this._router.navigate(['/financeiro/duplicata/compras']);
    }

    duplicataVendas() {
        this._router.navigate(['/financeiro/duplicata/vendas']);
    }

    pagamentosRemessas() {
        this._router.navigate(['/financeiro/lote-pagamento/remessas-geradas']);
    }

    pagamentosGerarRemessas() {
        this._router.navigate(['/financeiro/lote-pagamento/gerar-remessas']);
    }

    extrato() {
        this._router.navigate(['/financeiro/extrato']);
    }

    extratoDetalhe(id: number = null) {
        if (id) {
            this._router.navigate(['/financeiro/extrato/detalhe', id]);
        } else {
            this._router.navigate(['/financeiro/extrato/detalhe']);
        }
    }

    previsaoContasAPagar() {
        this._router.navigate(['/financeiro/previsao/contas-a-pagar']);
    }

    previsaoContasAPagarDetalhe(value: string) {
        if (value) {
            this._router.navigate([
                '/financeiro/previsao/contas-a-pagar/detalhe',
                value,
            ]);
        }
    }
    previsaoContasAPagarDetalheSemInformacao() {
        this._router.navigate(['/financeiro/previsao/contas-a-pagar/detalhe']);
    }

    previsaoContasAPagarDetalheRepete() {
        this._router.navigate([
            '/financeiro/previsao/contas-pagar/contas-a-pagar',
        ]);
    }

    previsaoContasAReceber() {
        this._router.navigate(['/financeiro/previsao/contas-a-receber']);
    }

    previsaoContasAReceberDetalhe(value: string) {
        if (value) {
            this._router.navigate([
                '/financeiro/previsao/contas-a-receber/detalhe',
                value,
            ]);
        }
    }

    previsaoContasAReceberDetalheSemInformacao() {
        this._router.navigate([
            '/financeiro/previsao/contas-a-receber/detalhe',
        ]);
    }

    previsaoContasAReceberDetalheRepete() {
        this._router.navigate([
            '/financeiro/previsao/contas-receber/contas-a-receber',
        ]);
    }

    /////////// RELATORIOS ///////////

    relatoriosFinanceiroCP(
        fil: number,
        ex: number,
        nrtrs: number,
        seqtrs: number
    ) {
        if (!nrtrs) {
            return;
        }
        this._router.navigate([
            'relatorios/financeiro/contaspagar',
            fil,
            ex,
            nrtrs,
            seqtrs,
        ]);
    }

    relatoriosFinanceiroCR(
        fil: number,
        ex: number,
        nrtrs: number,
        seqtrs: number
    ) {
        if (!nrtrs) {
            return;
        }
        this._router.navigate([
            'relatorios/financeiro/contasreceber',
            fil,
            ex,
            nrtrs,
            seqtrs,
        ]);
    }

    relatoriosOrdemDeCompra(numeroDaOrdem: number) {
        if (!numeroDaOrdem) {
            return;
        }
        this._router.navigate([
            'relatorios/compras/ordem-de-compra',
            numeroDaOrdem,
            '',
        ]);
    }

    relatoriosOrdemDeCompraIngles(numeroDoPedido: number, idioma: string) {
        if (!numeroDoPedido) {
            return;
        }
        this._router.navigate(
            ['relatorios/compras/ordem-de-compra', numeroDoPedido, idioma],
            { queryParams: { idioma: 'en-US' } }
        );
    }

    relatoriosPedidoDeCompra(numeroDoPedido: number) {
        if (!numeroDoPedido) {
            return;
        }
        this._router.navigate([
            'relatorios/compras/pedido-de-compra',
            numeroDoPedido,
        ]);
    }

    relatoriosRequisicaoDeCompra(numeroDaRequisicao: number) {
        if (!numeroDaRequisicao) {
            return;
        }
        this._router.navigate([
            'relatorios/compras/requisicao-de-compra',
            numeroDaRequisicao,
        ]);
    }

    relatoriosRequisicaoDeEstoque(numeroDaRequisicao: number) {
        if (!numeroDaRequisicao) {
            return;
        }
        this._router.navigate([
            'relatorios/estoque/requisicao-de-estoque',
            numeroDaRequisicao,
        ]);
    }

    relatoriosInventario(idInventario: number) {
        if (!idInventario) {
            return;
        }
        this._router.navigate(['relatorios/estoque/inventario', idInventario]);
    }

    relatoriosTransferencia(numeroDaMovimentacao: number) {
        if (!numeroDaMovimentacao) {
            return;
        }
        this._router.navigate([
            'relatorios/estoque/transferencia-de-estoque',
            numeroDaMovimentacao,
        ]);
    }

    relatoriosPedidoDeCompraCompletoFornecedor(numeroDoPedido: number) {
        if (!numeroDoPedido) {
            return;
        }
        this._router.navigate(
            ['relatorios/compras/pedido-de-compra', numeroDoPedido],
            { queryParams: { modelo: 'CompletoFornecedor' } }
        );
    }

    relatoriosPedidoDeCompraCompletoIngles(numeroDoPedido: number) {
        if (!numeroDoPedido) {
            return;
        }
        this._router.navigate(
            ['relatorios/compras/pedido-de-compra', numeroDoPedido],
            { queryParams: { modelo: 'CompletoIngles' } }
        );
    }

    relatoriosPedidoDeCompraSimplificado(numeroDoPedido: number) {
        if (!numeroDoPedido) {
            return;
        }
        this._router.navigate(
            ['relatorios/compras/pedido-de-compra', numeroDoPedido],
            { queryParams: { modelo: 'Simplificado' } }
        );
    }

    relatoriosPedidoDeCompraSintetico(numeroDoPedido: number) {
        if (!numeroDoPedido) {
            return;
        }
        this._router.navigate(
            ['relatorios/compras/pedido-de-compra', numeroDoPedido],
            { queryParams: { modelo: 'Sintetico' } }
        );
    }

    relatoriosDuplicatas(numeroDoPedido: number) {
        if (!numeroDoPedido) {
            return;
        }
        this._router.navigate(
            ['relatorios/compras/duplicatas', numeroDoPedido],
            { queryParams: { modelo: 'Sintetico' } }
        );
    }

    relatoriosCotacao(numeroDaOrdem: number, numeroDaCotacao: number) {
        if (!numeroDaOrdem || !numeroDaCotacao) {
            return;
        }
        this._router.navigate([
            'relatorios/compras/cotacao',
            numeroDaOrdem,
            numeroDaCotacao,
            '',
        ]);
    }

    relatoriosCotacaoIngles(
        numeroDaOrdem: number,
        numeroDaCotacao: number,
        idioma: string
    ) {
        if (!numeroDaOrdem || !numeroDaCotacao) {
            return;
        }
        this._router.navigate(
            [
                'relatorios/compras/cotacao',
                numeroDaOrdem,
                numeroDaCotacao,
                idioma,
            ],
            { queryParams: { idioma: 'en-US' } }
        );
    }

    relatoriosCotacaoComparativo(numeroDaOrdem: number) {
        if (!numeroDaOrdem) {
            return;
        }
        this._router.navigate([
            'relatorios/compras/cotacao-comparativo',
            numeroDaOrdem,
        ]);
    }

    relatoriosVendasFatura(numeroDaFatura: number) {
        if (!numeroDaFatura) {
            return;
        }
        this._router.navigate(['relatorios/vendas/fatura', numeroDaFatura]);
    }

    relatoriosVendasOrcamento(numeroDoOrcamento: number) {
        if (!numeroDoOrcamento) {
            return;
        }
        this._router.navigate([
            'relatorios/vendas/orcamento',
            numeroDoOrcamento,
        ]);
    }

    relatoriosVendasNotaFiscalDeServico(numeroDaNotaFiscal: number) {
        if (!numeroDaNotaFiscal) {
            return;
        }
        this._router.navigate([
            'relatorios/vendas/notafiscaldeservico',
            numeroDaNotaFiscal,
        ]);
    }

    relatoriosVendasNotaFiscalDeProduto(numeroDaNotaFiscal: number) {
        if (!numeroDaNotaFiscal) {
            return;
        }
        this._router.navigate([
            'relatorios/vendas/notafiscaldeproduto',
            numeroDaNotaFiscal,
        ]);
    }

    relatoriosVendasOrdemDeServico(numeroDaOrdem: number) {
        if (!numeroDaOrdem) {
            return;
        }
        this._router.navigate([
            'relatorios/vendas/ordemservico',
            numeroDaOrdem,
        ]);
    }

    relatoriosVendasPedidoDeVenda(numeroDoPedido: number) {
        if (!numeroDoPedido) {
            return;
        }
        this._router.navigate([
            'relatorios/vendas/pedidodevenda',
            numeroDoPedido,
        ]);
    }

    relatoriosVendasExpedicaoDeVenda(numeroDaNFe: number) {
        if (!numeroDaNFe) {
            return;
        }
        this._router.navigate([
            'relatorios/vendas/expedicaodevenda',
            numeroDaNFe,
        ]);
    }

    resultado(route) {
        this._router.navigate(['resultado'], { relativeTo: route });
    }
}
