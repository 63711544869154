export const environment = {
    production: false,
    local: false,

    otkerpApiAuth: 'https://otkwebdevauthapi.azurewebsites.net/api', // API - AUTH (DEV)
     otkerpApiAuthWeb: 'https://otkwebdevauthweb.azurewebsites.net/api', // API - AUTH WEB (DEV)

    otkerpApiGeneral: 'https://otkwebdevgeneralapi.azurewebsites.net/api', // API - GENERAL (DEV)

    otkerpApiPurchase: 'https://otkwebdevpurchaseapi.azurewebsites.net/api', // API - PURCHASE (DEV)

    otkerpApiStock: 'https://otkwebdevstockapi.azurewebsites.net/api', // API - STOCK (DEV)

    otkerpApiSales: 'https://otkwebdevsalesapi.azurewebsites.net/api', // API - SALES (DEV)

    otkerpApiFinancial: 'https://otkwebdevfinancialapi.azurewebsites.net/api', // API - FINANCIAL (DEV)

    otkerpApiSystemApplication:
        'https://otkwebdevsystemapplicationapi.azurewebsites.net/api', // API - SYSTEM APPLICATION (DEV)

    otkerpApiReports: 'https://otkwebdevreportsapi.azurewebsites.net/api', // API - REPORTS (DEV)
};
